import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Action from '../../../../Action'
import Icon from '../../../../Icon'
import { theme } from '../../../../../styles'

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: auto 3em 3em;
  max-width: 20em;
  padding: 0.3em;
  height: 3em;
  align-items: center;
  background: ${(props) => props.background};
`

const StyledIcon = styled(Icon)`
  border-radius: 0;
`

export const SensorValueListItem = ({ id, text, onDelete, onEdit }) => {
  return (
    <ItemGrid>
      <div>{text}</div>
      <Action onClick={() => onEdit(id)}>
        <StyledIcon name="edit" background={theme.colors.background} />
      </Action>
      <Action onClick={() => onDelete(id)}>
        <StyledIcon name="delete" background={theme.colors.alert} />
      </Action>
    </ItemGrid>
  )
}

SensorValueListItem.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

const SensorValueList = ({ items }) => {
  return (
    <>
      {items.map((item) => (
        <SensorValueListItem key={item.id} {...item} />
      ))}
    </>
  )
}

SensorValueList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onEdit: PropTypes.func.isRequired,
      onDelete: PropTypes.func.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default SensorValueList
