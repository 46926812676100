import { combineReducers } from 'redux'
import { createResource } from '../../../../../redux/reducers/resource'

const sensorValuesResource = createResource('sensor_values')
const sensorsResource = createResource('sensors')

export default combineReducers({
  sensor_values: sensorValuesResource.reducer,
  sensors: sensorsResource.reducer,
})

export const sensorValues = sensorValuesResource.selectors
export const sensors = sensorsResource.selectors
