import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { getAllFlashMessages } from '../../../redux/reducers'
import { ValidationError } from '../../forms'

const FlashMessage = (props) => {
  const { flashMessage } = props

  /* {t(`attributes:sensorValue:${key}`)}: {getNestedObjectValue(errors, key)} */
  return <ValidationError>{flashMessage.message}</ValidationError>
}

export class FlashMessages extends Component {
  componentDidMount = () => {}

  handleDismissMessage = (id) => {
    // TODO: dismiss message id
  }

  render() {
    const { flashMessages } = this.props

    return (
      <>
        {flashMessages &&
          flashMessages.map((fm) => {
            return <FlashMessage key={fm.id} flashMessage={fm} />
          })}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    flashMessages: getAllFlashMessages(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withTranslation()(FlashMessages)))
