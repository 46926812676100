import React, { useState, useRef } from 'react'
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
`

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
`

const MapContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 400px;
  z-index: 10;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  background-color: white; // Ensures that it covers the content below it when visible
`

const CloseButton = styled.div`
  cursor: pointer;
  height: 30px;
  font-size: 1.5em;
  text-align: right;
`

export const CoordinatePicker = ({
  attributeName,
  label,
  lat,
  lng,
  placeholder,
  inputName,
  initialZoomWithoutPosition,
  initialCoordinatesWithoutPosition
}) => {
  const [mapVisible, setMapVisible] = useState(false)
  const [markerPosition, setMarkerPosition] = useState({ lng, lat })
  const inputRef = useRef()

  const positionPresent = !!markerPosition.lng && !!markerPosition.lat
  const inputValue = positionPresent ? `POINT(${lng} ${lat})` : ''

  const handleInputClick = () => {
    setMapVisible(true)
  }

  const handleMapClick = (event) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    setMarkerPosition({ lat, lng })
    setMapVisible(false)
    inputRef.current.value = `POINT(${lng} ${lat})`
    // Exit fullscreen if enabled
    if (document.fullscreenElement) {
      document.exitFullscreen()
    }
  }

  return (
    <>
      <li className="string input optional stringish" id={`${attributeName}_input`}>
        <label htmlFor={attributeName} className="label">
          {label}
        </label>
        <Input
          ref={inputRef}
          id={attributeName}
          name={inputName}
          value={inputValue}
          type="text"
          placeholder={placeholder}
          onClick={handleInputClick}
          readOnly
        />
      </li>
      <Container visible={mapVisible}>
        <MapContainer visible={mapVisible}>
          <CloseButton onClick={() => setMapVisible(false)}>x</CloseButton>
          <LoadScript googleMapsApiKey={process.env.GOOGLE_API_KEY}>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={
                positionPresent
                  ? markerPosition
                  : initialCoordinatesWithoutPosition
              }
              mapTypeId="hybrid"
              zoom={positionPresent ? 14 : initialZoomWithoutPosition}
              onClick={handleMapClick}
            >
              {positionPresent && (
                <Marker position={markerPosition} />
              )}
            </GoogleMap>
          </LoadScript>
        </MapContainer>
      </Container>
    </>
  )
}

export default CoordinatePicker
