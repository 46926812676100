import React from 'react'
import './ButtonGroup.css'

export const ButtonGroup = ({ children, vertical, horizontal, label }) => {
  const className = vertical
    ? 'c-button-group-vertical'
    : 'c-button-group-horizontal'

  return (
    <div className={className}>
      {label} {children}
    </div>
  )
}
