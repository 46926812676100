import React, { Component } from 'react'
import { I18nextProvider } from 'react-i18next'
import styled, { ThemeProvider } from 'styled-components'
import i18n from '../../../i18n'
import { theme } from '../../../styles'
import { Checkbox, LineChart, Timeline } from './components'
import ErrorBoundary from '../../../lib/ErrorBoundary'

import DateRangeSelector from './components/DateRangeSelector'

const normalizeByKey = (array, key) => {
  const normalizedObj = {}
  array.forEach((x) => {
    normalizedObj[x.key] = x
  })
  return normalizedObj
}

const normalizedObjectToArray = (object) => {
  return Object.keys(object).map((key, index) => object[key])
}

const ChartBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

const OrderedChart = styled.div`
  order: ${(props) => props.order};
`

const Chart = ({ type, title, order, ...props }) => {
  return (
    <OrderedChart order={order}>
      <h3>{title}</h3>
      {type === 'timeline' && <Timeline {...props} />}
      {type === 'line' && <LineChart {...props} />}
    </OrderedChart>
  )
}

const generateOrder = () => parseInt(Date.now() / 1000)

const DateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChartButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  padding-bottom: 2em;
`

export class FieldModuleCharts extends Component {
  constructor(props) {
    super(props)

    const showStates = props.charts.map((x) => ({
      key: x.key,
      title: x.title,
      show: x.show,
      order: generateOrder(),
    }))

    this.state = {
      ...props,
      showStates: normalizeByKey(showStates, 'key'),
    }
  }

  handleDateChanged = (e) => {
    const { startDate, endDate } = e.values
    const { dateTimePicker } = this.state
    this.setState({ dateTimePicker: { ...dateTimePicker, startDate, endDate } })
  }

  handleShowChartChanged(e) {
    const { showStates } = this.state
    const checkboxState = showStates[e.key]
    const newCheckboxState = {
      ...checkboxState,
      show: !checkboxState.show,
      order: generateOrder(),
    }
    this.setState({
      showStates: { ...showStates, [checkboxState.key]: newCheckboxState },
    })
  }

  render() {
    const { charts } = this.props
    const { dateTimePicker, showStates } = this.state
    const { minDate, maxDate, startDate, endDate } = dateTimePicker

    return (
      <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <ChartButtonsContainer>
              {normalizedObjectToArray(showStates).map(
                ({ key, title, show }) => (
                  <Checkbox
                    key={key}
                    id={key}
                    label={title}
                    onHandler={() =>
                      this.handleShowChartChanged({ key, title, show })
                    }
                    showState={show}
                  />
                )
              )}
            </ChartButtonsContainer>
            <DateRangeWrapper>
              <DateRangeSelector
                minDate={minDate}
                maxDate={maxDate}
                startDate={startDate}
                endDate={endDate}
                onDateChanged={this.handleDateChanged}
              />
            </DateRangeWrapper>
            <ChartBox>
              {charts.map(
                ({ type, key, title, options, ...otherProps }) =>
                  showStates[key].show && (
                    <Chart
                      order={showStates[key].order}
                      key={key}
                      type={type}
                      title={title}
                      startDate={startDate}
                      endDate={endDate}
                      onError={(error) => console.log(error)}
                      {...otherProps}
                      {...options}
                    />
                  )
              )}
            </ChartBox>
          </ThemeProvider>
        </I18nextProvider>
      </ErrorBoundary>
    )
  }
}

export default FieldModuleCharts
