import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #cc3300;
  height: 48px;
  text-align: center;
  font-size: 32;
  font-weight: bold;
  color: #ffffff;
`

export const MaintainanceModeBanner = ({ activated }) => {
  return (
    <>{activated && <Background>Wartungsmodus ist aktiviert</Background>}</>
  )
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const fetchMaintenanceModeState = (token, url, callback) => {
  window
    .fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: 'same-origin',
    })
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      } else {
        console.log('Error', response)
      }
    })
    .then((data) => {
      if (data) {
        callback(data.maintenance_mode)
      }
    })
    .catch((error) => console.error('Error:', error))
}

export const MaintenanceModeBannerWithPolling = ({ token, url, activated }) => {
  const [activatedState, setActivatedState] = useState(activated)

  useInterval(() => {
    fetchMaintenanceModeState(token, url, (activated) =>
      setActivatedState(activated)
    )
  }, 5000)

  return <MaintainanceModeBanner activated={activatedState} />
}

export default MaintenanceModeBannerWithPolling
