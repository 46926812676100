import React from 'react'
import './Button.css'

export const Button = ({ name, label, onClick, ...props }) => (
  <div className="c-button-wrapper">
    <input
      className="c-button"
      type="submit"
      onClick={onClick}
      value={label}
      name={name}
      {...props}
    />
  </div>
)
