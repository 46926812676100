import React, { Fragment } from 'react'
import { Formik, Field, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { withTranslation } from 'react-i18next'
import { ValidationError, FormGroupRow } from '../../forms'
import styled from 'styled-components'

const NotifyParentAboutFormState = ({ onNotifyChanges }) => {
  const formikContext = useFormikContext()
  const { values, isValid, dirty } = formikContext
  React.useEffect(() => {
    onNotifyChanges(values, isValid, dirty)
  }, [isValid, values, onNotifyChanges, dirty])
  return null
}

const settingKeysByInputType = {
  SdiSensor: ['avg', 'ovl1', 'ovl2', 'uvl1', 'uvl2', 'hyst'],
  FmsSensor: ['avg', 'ovl1', 'ovl2', 'uvl1', 'uvl2', 'hyst'],
  FloatSensor: ['avg', 'ovl1', 'ovl2', 'uvl1', 'uvl2', 'hyst'],
  DigitalSensor: ['rt', 'rtrs', 'ecua', 'rtcs'],
  AnalogSensor: [
    'cycle',
    'dly',
    'avg',
    'ovl1',
    'ovl2',
    'ovl3',
    'uvl1',
    'uvl2',
    'uvl3',
    'hyst',
  ],
}

const validationSchemaByInputType = (inputType, t) => {
  const msgNan = t('formValidationErrors:nan')
  const msgString = t('formValidationErrors:string')
  const msgRequired = t('formValidationErrors:required')

  return Yup.object().shape({
    dataSourceKey: Yup.string(msgString)
      .typeError(msgString)
      .required(msgRequired),
    unit: Yup.string().typeError(msgString).required(msgRequired),
    name: Yup.string().typeError(msgString).required(msgRequired),
    rangeSrcMin: Yup.number().typeError(msgNan).required(msgRequired),
    rangeSrcMax: Yup.number().typeError(msgNan).required(msgRequired),
    rangeDstMin: Yup.number().typeError(msgNan).required(msgRequired),
    rangeDstMax: Yup.number().typeError(msgNan).required(msgRequired),
    alarmSystemConfig: Yup.object().shape({
      rt: Yup.number().typeError(msgNan),
      rtrs: Yup.number().typeError(msgNan),
      ecua: Yup.number().typeError(msgNan),
      rtcs: Yup.number().typeError(msgNan),
      cycle: Yup.number().typeError(msgNan),
      dly: Yup.number().typeError(msgNan),
      avg: Yup.number().typeError(msgNan),
      hyst: Yup.number().typeError(msgNan),
      ovl1: Yup.number().typeError(msgNan),
      ovl2: Yup.number().typeError(msgNan),
      ovl3: Yup.number().typeError(msgNan),
      uvl1: Yup.number().typeError(msgNan),
      uvl2: Yup.number().typeError(msgNan),
      uvl3: Yup.number().typeError(msgNan),
    }),
  })
}

const StyledField = styled(Field)`
  margin-left: 0.5em;
`

const defaultValues = {
  dataSourceKey: '',
  unit: '',
  name: '',
  rangeSrcMin: -1000000,
  rangeSrcMax: 1000000,
  rangeDstMin: -1000000,
  rangeDstMax: 1000000,
  decimalsSrc: 0,
  decimalsDst: 0,
  showOnMap: true,
  alarmSystemConfig: {
    cycle: '',
    dly: '',
    avg: '',
    hyst: '',
    ovl1: '',
    ovl2: '',
    ovl3: '',
    uvl1: '',
    uvl2: '',
    uvl3: '',
    rt: '',
    rtrs: '',
    ecua: '',
    rtcs: '',
  },
}

const replaceNullValues = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = ''
    }
  })
}

const FormSensorValue = ({
  t,
  onNotifyChanges,
  values,
  units,
  dataSourceKeys,
  inputType,
}) => {
  const settingKeys = settingKeysByInputType[inputType]
  const initialValues = values ? { ...defaultValues, ...values } : defaultValues

  // replace null values with empty strings
  replaceNullValues(initialValues)
  replaceNullValues(initialValues.alarmSystemConfig)

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      validationSchema={validationSchemaByInputType(inputType, t)}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
        } = props
        return (
          <Form>
            <NotifyParentAboutFormState
              onNotifyChanges={onNotifyChanges}
              isValid={isValid}
            />

            <h2>{t('attributes:sensorValue:dataSourceHeader')}</h2>
            <FormGroupRow>
              <label htmlFor="name" style={{ display: 'block' }}>
                {t('attributes:sensorValue:name')}
              </label>
              <StyledField
                as="input"
                name="name"
                id="label"
                placeholder=""
                type="text"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.name && touched.name
                    ? 'text-input error'
                    : 'text-input'
                }
              />
            </FormGroupRow>
            {errors.name && touched.name && (
              <ValidationError>{errors.name}</ValidationError>
            )}

            <FormGroupRow>
              <label htmlFor="dataSourceKey" style={{ display: 'block' }}>
                {t('attributes:sensorValue:dataSourceKey')}
              </label>
              <Field as="select" name="dataSourceKey">
                <option value="" disabled hidden />
                {dataSourceKeys.map((dsk) => {
                  return (
                    <option key={dsk} value={dsk}>
                      {t(
                        `attributes:sensorValue:dataSourceOptions:${inputType}:${dsk}`
                      )}
                    </option>
                  )
                })}
              </Field>
            </FormGroupRow>
            {errors.dataSourceKey && touched.dataSourceKey && (
              <ValidationError>{errors.dataSourceKey}</ValidationError>
            )}

            <h2>{t('attributes:sensorValue:displaySettingsHeader')}</h2>

            <FormGroupRow>
              <label htmlFor="rangeSrcMin" style={{ display: 'block' }}>
                {t('attributes:sensorValue:rangeSrc')}
              </label>
              <StyledField
                as="input"
                name="rangeSrcMin"
                width="2em"
                id="rangeSrcMin"
                placeholder={t('attributes:sensorValue:rangeSrcMinPlaceholder')}
                type="text"
                value={values.rangeSrcMin}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.rangeSrcMin && touched.rangeSrcMin
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              <StyledField
                as="input"
                name="rangeSrcMax"
                id="rangeSrcMax"
                placeholder={t('attributes:sensorValue:rangeSrcMaxPlaceholder')}
                type="text"
                value={values.rangeSrcMax}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.rangeSrcMax && touched.rangeSrcMax
                    ? 'text-input error'
                    : 'text-input'
                }
              />
            </FormGroupRow>
            {errors.rangeSrcMin && touched.rangeSrcMin && (
              <ValidationError>{errors.rangeSrcMin}</ValidationError>
            )}
            {errors.rangeSrcMax && touched.rangeSrcMax && (
              <ValidationError>{errors.rangeSrcMax}</ValidationError>
            )}

            <FormGroupRow>
              <label htmlFor="rangeDstMin" style={{ display: 'block' }}>
                {t('attributes:sensorValue:rangeDst')}
              </label>
              <StyledField
                as="input"
                name="rangeDstMin"
                id="rangeDstMin"
                placeholder={t('attributes:sensorValue:rangeDstMinPlaceholder')}
                type="text"
                value={values.rangeDstMin}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.rangeDstMin && touched.rangeDstMin
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              <StyledField
                as="input"
                name="rangeDstMax"
                id="rangeDstMax"
                placeholder={t('attributes:sensorValue:rangeDstMaxPlaceholder')}
                type="text"
                value={values.rangeDstMax}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.rangeDstMax && touched.rangeDstMax
                    ? 'text-input error'
                    : 'text-input'
                }
              />
            </FormGroupRow>
            {errors.rangeDstMin && touched.rangeDstMin && (
              <ValidationError>{errors.rangeDstMin}</ValidationError>
            )}
            {errors.rangeDstMax && touched.rangeDstMax && (
              <ValidationError>{errors.rangeDstMax}</ValidationError>
            )}

            {['decimalsSrc', 'decimalsDst'].map((key) => (
              <Fragment key={key}>
                <FormGroupRow>
                  <label htmlFor={key} style={{ display: 'block' }}>
                    {t(`attributes:sensorValue:${key}`)}
                  </label>
                  <StyledField
                    as="input"
                    name={key}
                    id={key}
                    placeholder=""
                    type="text"
                    value={values[key]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors[key] && touched[key]
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                </FormGroupRow>
                {errors[key] && touched[key] && (
                  <ValidationError>{errors[key]}</ValidationError>
                )}
              </Fragment>
            ))}

            <FormGroupRow>
              <label htmlFor="unit" style={{ display: 'block' }}>
                {t('attributes:sensorValue:unit')}
              </label>
              <Field as="select" name="unit">
                <option value="" disabled hidden />
                {units.map((unit) => (
                  <option key={unit} value={unit}>
                    {t(`attributes:sensorValue:unitOptions:${unit}`)}
                  </option>
                ))}
              </Field>
            </FormGroupRow>
            {errors.unit && touched.unit && (
              <ValidationError>{errors.unit}</ValidationError>
            )}

            <FormGroupRow>
              <label htmlFor="showOnMap" style={{ display: 'block' }}>
                {t('attributes:sensorValue:showOnMap')}
              </label>
              <StyledField
                as="input"
                name="showOnMap"
                id="showOnMap"
                type="checkbox"
                value={values.showOnMap}
                checked={values.showOnMap}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.showOnMap && touched.showOnMap
                    ? 'text-input error'
                    : 'text-input'
                }
              />
            </FormGroupRow>
            {errors.showOnMap && touched.showOnMap && (
              <ValidationError>{errors.showOnMap}</ValidationError>
            )}

            <h2>{t('attributes:sensorValue:alarmSystemConfigHeader')}</h2>
            {settingKeys.map((s) => (
              <Fragment key={s}>
                <FormGroupRow>
                  <label
                    htmlFor={`alarmSystemConfig.${s}`}
                    style={{ display: 'block' }}
                  >
                    {t(`attributes:sensorValue:alarmSystemConfig:${s}`)}
                  </label>
                  <StyledField
                    as="input"
                    name={`alarmSystemConfig.${s}`}
                    id={`alarmSystemConfig.${s}`}
                    placeholder=""
                    type="text"
                    value={values.alarmSystemConfig[s]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.alarmSystemConfig &&
                      errors.alarmSystemConfig[s] &&
                      touched.alarmSystemConfig &&
                      touched.alarmSystemConfig[s]
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                </FormGroupRow>
                {errors.alarmSystemConfig &&
                  errors.alarmSystemConfig[s] &&
                  touched.alarmSystemConfig &&
                  touched.alarmSystemConfig[s] && (
                    <ValidationError>
                      {errors.alarmSystemConfig[s]}
                    </ValidationError>
                  )}
              </Fragment>
            ))}
          </Form>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(FormSensorValue)
