import React from 'react'
import Chart from 'react-google-charts'
import camelize from 'camelize'

// Reference : https://developers.google.com/chart/interactive/docs/gallery/timeline

// TODO: move fetching data to hoc ("ChartDataProvider" for example)

export class Timeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: props.columns,
      rows: props.rows,
      loading: false,
    }
  }

  componentDidMount() {
    const { startDate, endDate } = this.props
    this.fetchData(startDate, endDate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.fetchData(startDate, endDate)
    }
  }

  fetchData = (startDate, endDate) => {
    const { dataUrl, token, onError } = this.props
    const url = `${dataUrl}&start_date=${startDate}&end_date=${endDate}`
    this.setState({ loading: true })

    window
      .fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then(function (response) {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error: Loading timeline data!')
        }
      })
      .then((response) => {
        this.dataReceived(response)
      })
      .catch((error) => {
        !onError && console.error('Error:', error)
        onError && onError(error)
      })
  }

  dataReceived = (data) => {
    const { rows, columns } = camelize(data)
    this.setState({ rows, columns, loading: false })
  }

  render() {
    const {
      showBarLabels,
      colorByRowLabel,
      title,
      singleColor,
      startDate,
      endDate,
    } = this.props
    const { columns, rows, loading } = this.state

    console.log('Timeline dates', startDate, endDate)

    const rowLength = rows[0] ? rows[0].length : 0
    const rowsWithDates =
      rowLength > 0
        ? rows.map((r) => {
            // Convert the last two elements and copy all leading elements [0, ..., n-2, n-1]
            return [
              ...r.slice(0, rowLength - 2),
              ...[
                new Date(r[rowLength - 2] * 1000),
                new Date(r[rowLength - 1] * 1000),
              ],
            ]
          })
        : null

    const options = {
      timeline: {
        colorByRowLabel: colorByRowLabel,
        showBarLabels: showBarLabels,
        title: title,
        singleColor: singleColor,
      },
    }

    return (
      <div>
        {/*{loading && <p>Lade Daten...</p>}*/}
        {rowsWithDates ? (
          <Chart
            chartType="Timeline"
            data={[columns, ...rowsWithDates]}
            width="100%"
            height="400px"
            options={options}
          />
        ) : (
          !loading && <p>Keine Daten zum Anzeigen vorhanden.</p>
        )}
      </div>
    )
  }
}

export default Timeline
