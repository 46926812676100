import React from 'react'
import './Checkbox.css'

export const Checkbox = (props) => {
  const { label, onHandler, showState } = props

  return (
    <span onClick={onHandler} className="c-chart-checkboxes">
      <label>{label}</label>
      <input type="checkbox" checked={showState} readOnly />
    </span>
  )
}

export default Checkbox
