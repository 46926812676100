import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../../../styles'

import i18n from '../../../../i18n'

import { storeToken } from '../../../../lib/auth'

import configureStore from '../redux/stores'
import { setCurrentSensor } from '../redux/actions'

import SensorValues from './sensor_values/List'

const App = ({ token, units, sensor }) => {
  // store the token => in WebApp this works a little different (here we dont need to sign in)
  storeToken(token)

  const store = configureStore()

  // dispatch setCurrentSensor => in the WebApp we should fetch all Sensors and call setCurrentSensor
  // after a real sensor selection
  store.dispatch(setCurrentSensor(sensor.id))

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <SensorValues units={units} />
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  )
}

export default App
