import React, { Component } from 'react'
import { Client } from 'paho-mqtt'

// TODO: move these styles into scss files
// currently not possible, because the active_admin styles
// override everything else...
const buttonStyle = {
  width: '8em',
  height: '2em',
  margin: '1em 1em 1em 0em',
}

export default class FieldModuleParameters extends Component {
  client = null

  constructor(props) {
    super(props)

    this.state = {
      varNumber: '',
      varValue: '',
      receivedValues: [],
    }
  }

  componentDidMount = () => {
    const { mqttHost, mqttPort, mqttToken, useSSL } = this.props.mqttConfig
    this.client = new Client(mqttHost, mqttPort, '')
    this.client.connect({
      onSuccess: this.onConnect,
      onFailure: this.onFailure,
      userName: mqttToken,
      password: 'doesntmatter',
      useSSL: useSSL,
    })

    this.client.onMessageArrived = this.onMessageArrived
    this.client.onConnectionLost = this.onConnectionLost
  }

  componentWillUnmount = () => {
    this.client.disconnect()
  }

  onConnect = (params) => {
    const { topics } = this.props.mqttConfig
    this.client.subscribe(topics.subscribe.read_variable_response)
    this.client.subscribe(topics.subscribe.write_variable_response)
  }

  onFailure = (params) => {
    console.log('onFailure: ', params)
  }

  onMessageArrived = (message) => {
    const msg = JSON.parse(message.payloadString)
    const receivedValues = [...this.state.receivedValues]
    const index = this.state.receivedValues.findIndex(
      (x) => x.varNumber === msg.var_nr
    )
    const receivedValue = { varNumber: msg.var_nr, varValue: msg.value }

    // replace or add the received value
    if (index !== -1) {
      receivedValues[index] = receivedValue
    } else {
      receivedValues.push(receivedValue)
    }

    // sort variables ascending
    receivedValues.sort(function (a, b) {
      var value1 = a.varNumber.toLowerCase()
      var value2 = b.varNumber.toLowerCase()
      if (value1 < value2) {
        return -1
      }
      if (value1 > value2) {
        return 1
      }
      return 0
    })

    this.setState({ receivedValues })
  }

  onConnectionLost = (params) => {
    console.log('onConnectionLost: ', params)
  }

  handleRead = () => this.handleSubmit('read_variable')
  handleWrite = () => this.handleSubmit('write_variable')

  handleSubmit = (mode) => {
    const topic = this.props.mqttConfig.topics.publish[mode]
    const payload = {
      var_nr: this.state.varNumber,
    }

    if (mode === 'write_variable') {
      payload.value = this.state.varValue
    }

    // check variable number
    if (isNaN(this.state.varNumber) || this.state.varNumber === '') {
      window.alert('Please enter a valid variable number!')
      return
    }

    this.client.publish(topic, JSON.stringify(payload))
  }

  handleVarNrChange = (event) => {
    this.setState({ varNumber: event.target.value })
  }

  handleVarValueChange = (event) => {
    this.setState({ varValue: event.target.value })
  }

  render() {
    const { receivedValues } = this.state

    return (
      <div>
        <label>
          Variable Number:
          <input
            type="text"
            value={this.state.varNumber}
            onChange={this.handleVarNrChange}
          />
        </label>
        <label>
          Value:
          <input
            type="text"
            value={this.state.varValue}
            onChange={this.handleVarValueChange}
          />
        </label>
        <div>
          <input
            style={buttonStyle}
            onClick={this.handleRead}
            name="read"
            value="Read"
            type="submit"
          />
          <input
            style={buttonStyle}
            onClick={this.handleWrite}
            name="write"
            value="Write"
            type="submit"
          />
        </div>
        <div>
          {receivedValues.length > 0 ? (
            <>
              <div>Current Values</div>
              <br />
              <table cellSpacing="0" cellPadding="0">
                <thead>
                  <tr className="row">
                    <th>VarNumber</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {receivedValues.map((v) => (
                    <tr key={v.varNumber} className="row">
                      <td>{v.varNumber}</td>
                      <td>{v.varValue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : null}
        </div>
      </div>
    )
  }
}
