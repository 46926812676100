import React from 'react'

export const PropertyTableRow = ({ value, label, actions }) => (
  <tr className="row">
    <th width="160em">{label}: </th>
    <td>{value}</td>
    {actions && <td>{actions()}</td>}
  </tr>
)

export const PropertyTable = ({ rows, children }) => {
  return (
    <table cellSpacing="0" cellPadding="0">
      <tbody>
        {!children &&
          rows.map((row) => <PropertyTableRow key={row.label} {...row} />)}
        {children && children}
      </tbody>
    </table>
  )
}
