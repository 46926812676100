import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from '../../../../Modal'
import Icon from '../../../../Icon'
import Action from '../../../../Action'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import FormSensorValue from '../../../forms/SensorValue'
import {
  getSensorValueById,
  getCurrentSensorValueId,
  getCurrentSensorId,
  getSensorById,
} from '../../redux/reducers'
import { sensorValues } from '../../redux/actions/resource'
import FlashMessages from '../../FlashMessages'
import { dismissAllFlashMessages } from '../../../../../redux/actions'

const ModalBackdrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0;
  background-color: rgb(78, 78, 78);
  z-index: 999;
  opacity: 0.3;
`

const ModalWrapper = styled.div`
  background: green;
  z-index: 1000;
  position: relative;
`

const Scrollable = styled.div`
  overflow-y: scroll;
  padding-top: 2em;
  padding-right: 1em;
  margin-right: 0.5em;
`

export class SensorValuesEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValid: false,
      values: {},
    }
  }

  componentDidMount = () => {
    const { currentSensorValueId, fetchSensorValue } = this.props
    currentSensorValueId && fetchSensorValue(currentSensorValueId)
  }

  handleModalCancelClick = () => {
    const { onModalCancel, dismissAllFlashMessages } = this.props

    // reset flash messages
    dismissAllFlashMessages()

    onModalCancel()
  }

  handleModalConfirmClick = () => {
    const { values, isValid, dirty } = this.state
    const {
      updateSensorValue,
      createSensorValue,
      currentSensorValueId,
      currentSensorId,
      onModalConfirm,
      dismissAllFlashMessages,
    } = this.props

    // reset flash messages
    dismissAllFlashMessages()

    // if form has not changed (is not dirty) => we can close it without saving anything
    if (!dirty) {
      console.log('Nothing changed => dont submit')
      onModalConfirm()
    }

    if (currentSensorId && currentSensorValueId && isValid) {
      // update sensor value if there is already an id
      console.log('UPDATE', currentSensorValueId, values)
      updateSensorValue(currentSensorValueId, values).promise.then(
        ({ json }) => {
          json.id && onModalConfirm()
        }
      )
    } else if (currentSensorId && isValid) {
      // create new sensor value if there is no id yet
      console.log('CREATE', values)
      createSensorValue(currentSensorId, values).promise.then(({ json }) => {
        json.id && onModalConfirm()
      })
    }
  }

  handleOnNotifyChanges = (values, isValid, dirty) => {
    console.log('handleNotifyChanges', values, 'isValid', isValid)
    this.setState({
      isValid,
      dirty,
      values: { ...values },
    })
  }

  render() {
    const {
      theme,
      sensorValue,
      showEditModal,
      currentSensor,
      units,
    } = this.props
    const inputType = currentSensor && currentSensor.type

    const modalTitle =
      sensorValue && currentSensor
        ? `Sensorwert ${
            sensorValue.name ? '"' + sensorValue.name + '"' : ''
          } für Sensor "${currentSensor.name}" ${
            sensorValue.id ? 'bearbeiten' : 'anlegen'
          }`
        : null // TODO: translate title

    return (
      <>
        {showEditModal && (
          <>
            <ModalBackdrop />
            <ModalWrapper>
              <Modal
                theme={theme}
                hint={modalTitle}
                actions={
                  <>
                    <Action vertical onClick={this.handleModalConfirmClick}>
                      <Icon
                        name="confirm"
                        background={theme.colors.brandSecondary}
                      />
                    </Action>
                    <Action vertical onClick={this.handleModalCancelClick}>
                      <Icon
                        name="cancel"
                        background={theme.colors.background}
                      />
                    </Action>
                  </>
                }
              >
                <Scrollable>
                  <FlashMessages />
                  <FormSensorValue
                    values={sensorValue}
                    units={units}
                    dataSourceKeys={
                      currentSensor && currentSensor.dataSourceKeys
                    }
                    onNotifyChanges={this.handleOnNotifyChanges}
                    inputType={inputType}
                  />
                </Scrollable>
              </Modal>
            </ModalWrapper>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentSensorValueId: getCurrentSensorValueId(state),
    sensorValue: getSensorValueById(state, state.currentSensorValueId) || {},
    currentSensorId: getCurrentSensorId(state),
    currentSensor: getSensorById(state, state.currentSensorId),
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetchSensorValue: (id) => dispatch(sensorValues.read(id)),
  createSensorValue: (sensorId, attributes) =>
    dispatch(sensorValues.create({ sensorId }, attributes)),
  updateSensorValue: (sensorValueId, attributes) =>
    dispatch(sensorValues.update(sensorValueId, undefined, attributes)),
  dismissAllFlashMessages: () => dispatch(dismissAllFlashMessages()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withTranslation()(SensorValuesEdit)))
