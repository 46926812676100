import React from 'react'
import { DateTime, StyledForm, FormField } from '../../../forms'
import styled from 'styled-components'
import { Form, FormSpy } from 'react-final-form'
import parseISO from 'date-fns/parseISO'
import './DateRangeSelector.css'

const DatePickerWrapper = styled.div`
  display: flex;
  max-width: 60em;
`

const DateRangeSelector = ({
  minDate,
  maxDate,
  startDate,
  endDate,
  onDateChanged,
  ...props
}) => {
  return (
    <DatePickerWrapper {...props}>
      <Form
        initialValues={{
          startDate: startDate,
          endDate: endDate,
        }}
        onSubmit={(e) => {
          console.log('DateRangeSelector#onSubmit', e)
        }}
      >
        {({ handleSubmit, submitError, values }) => (
          <StyledForm id="dateRangeForm" divided>
            <FormField
              name="startDate"
              label="Von"
              value={startDate}
              component={DateTime}
              required
            />
            <FormField
              name="endDate"
              label="Bis"
              value={endDate}
              component={DateTime}
              minDate={parseISO(minDate)}
              required
            />
            <FormSpy
              onChange={(e) => {
                // check if something changed
                if (!e.pristine) {
                  onDateChanged(e)
                }
              }}
              subscription={{ values: true, pristine: true }}
            />
          </StyledForm>
        )}
      </Form>
    </DatePickerWrapper>
  )
}

export default DateRangeSelector
