import React from 'react'

export const ConnectionIndicator = ({ connected }) => {
  if (connected) {
    return (
      <span style={{ verticalAlign: 'sub', fontSize: '1.6em', color: 'green' }}>
        &#9679;
      </span>
    )
  } else {
    return (
      <span style={{ verticalAlign: 'sub', fontSize: '1.6em', color: 'red' }}>
        &#9679;
      </span>
    )
  }
}

export default ConnectionIndicator
