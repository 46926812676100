import React, { Component } from 'react'
import { Client } from 'paho-mqtt'

const dropdownStyle = {
  marginLeft: '40px',
  padding: '4px',
}

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
}

const gridStyle = {
  marginLeft: '3em',
  marginTop: '1em',
  display: 'grid',
  gridTemplateColumns: '15em 10em',
  gridRowGap: '0.5em',
}

const buttonStyle = {
  marginLeft: '1em',
  width: '8em',
}

const PackageDropdown = ({ packages, onChange, value }) => {
  const PackageItems = () =>
    packages
      ? packages.map((pkg) => {
          const text = `${pkg.Package} ${pkg.Version}`

          return (
            <option key={pkg.MD5sum} value={pkg.MD5sum}>
              {text}
            </option>
          )
        })
      : null

  return (
    <select
      id="chart-dropdown"
      onChange={onChange}
      value={value}
      style={dropdownStyle}
    >
      <option value="" />
      <PackageItems />
    </select>
  )
}

export default class CentralModuleSoftwareUpdate extends Component {
  client = null

  constructor(props) {
    super(props)

    this.state = {
      selectedPackage: '',
      cmStatus: {
        online_status: '',
        update_status: '',
        cm_software_version: '',
        cm_updater_version: '',
        timestamp: '',
      },
    }
  }

  componentDidMount = () => {
    const { mqttHost, mqttPort, mqttToken, useSSL } = this.props.mqttConfig
    this.client = new Client(mqttHost, mqttPort, '')
    this.client.connect({
      onSuccess: this.onConnect,
      onFailure: this.onFailure,
      userName: mqttToken,
      password: 'doesntmatter',
      useSSL: useSSL,
    })

    this.client.onMessageArrived = this.onMessageArrived
    this.client.onConnectionLost = this.onConnectionLost
  }

  componentWillUnmount = () => {
    this.client.disconnect()
  }

  onConnect = (params) => {
    this.client.subscribe(this.props.mqttConfig.topic)
  }

  onFailure = (params) => {
    console.log('onFailure: ', params)
  }

  onMessageArrived = (params) => {
    if (params.topic === this.props.mqttConfig.topic) {
      const newStatus = JSON.parse(params.payloadString)

      this.setState({
        cmStatus: newStatus,
      })
    }
  }

  onConnectionLost = (params) => {
    console.log('onConnectionLost: ', params)
  }

  handleChange = (e) => {
    let selectedPackage = ''

    if (e.target.value !== '') {
      selectedPackage = this.props.installablePackages.find(
        (x) => x.MD5sum === e.target.value
      )
    }

    this.setState({ selectedPackage })
  }

  handleInstall = (e) => {
    e.preventDefault()

    if (this.state.selectedPackage === '') {
      window.alert('Please select the target version first.')
      return
    }

    window
      .fetch(
        `${this.props.installPath}/?target_version=${this.state.selectedPackage.Version}`,
        {
          method: 'GET',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': this.props.csrfToken,
          },
          credentials: 'same-origin',
        }
      )
      .then((res) => res)
      .catch((error) => console.error('Error:', error))
  }

  render() {
    const { installablePackages } = this.props
    const value = this.state.selectedPackage.MD5sum
    const { cmStatus } = this.state

    return (
      <div>
        <div style={containerStyle}>
          <PackageDropdown
            onChange={this.handleChange}
            packages={installablePackages}
            value={value}
          />
          <input
            style={buttonStyle}
            onClick={this.handleInstall}
            name="install"
            value="Install"
            type="submit"
          />
        </div>
        <div style={gridStyle}>
          <div>CM Software Version</div>
          <div>{cmStatus.cm_software_version}</div>
          <div>CM Updater Version</div>
          <div>{cmStatus.cm_updater_version}</div>
          <div>UpdaterService-Status</div>
          <div>{cmStatus.online_status}</div>
          <div>Update-Status</div>
          <div>{cmStatus.update_status}</div>
          <div>Timestamp</div>
          <div>{cmStatus.timestamp}</div>
        </div>
      </div>
    )
  }
}
