import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from '../../../../Icon'
import Action from '../../../../Action'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import SensorValueList from './SensorValueList'
import { getAllSensorValues, getCurrentSensorId } from '../../redux/reducers'
import { sensorValues, sensors } from '../../redux/actions/resource'
import { setCurrentSensorValue } from '../../redux/actions'
import EditModal from './Edit'

const SensorValuesHeader = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;
`

const ButtonLabel = styled.div`
  margin-right: 0.5em;
`

export class SensorValues extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showEditModal: false,
      values: {},
    }
  }

  componentDidMount = () => {
    this.props.fetchSensor(this.props.currentSensorId)
    this.props.fetchSensorValues(this.props.currentSensorId)
  }

  toggleModal = () => {
    this.setState({ showEditModal: !this.state.showEditModal })
  }

  handleOnEdit = (id) => {
    id && this.props.setCurrentSensorValue(id)
    this.toggleModal()
  }

  handleOnDelete = (id) => {
    const msg =
      'Soll der Sensor-Wert wirklich gelöscht werden? \n' +
      'Die Sensor-Daten werden dabei nicht gelöscht, sondern nur die Zuordnung zu diesem Sensor.'
    if (window.confirm(msg)) {
      id && this.props.deleteSensorValue(id)
    }
  }

  handleModalCancel = () => {
    this.toggleModal()
    this.props.resetCurrentSensorValue()
  }

  handleModalConfirm = () => {
    this.toggleModal()
    this.props.resetCurrentSensorValue()
  }

  handleAddSensorValue = () => {
    this.toggleModal()
  }

  render() {
    const { showEditModal } = this.state
    const { theme, sensorValues, units, t } = this.props

    const items = sensorValues.map((sv) => {
      return {
        id: sv.id,
        text:
          sv.unit !== ''
            ? `${sv.name} (${t(
                `attributes:sensorValue:unitOptions:${sv.unit}`
              )})`
            : sv.name,
        onEdit: this.handleOnEdit,
        onDelete: this.handleOnDelete,
      }
    })

    return (
      <>
        <SensorValuesHeader>
          <ButtonLabel>Neuer Sensorwert</ButtonLabel>
          <Action onClick={this.handleAddSensorValue}>
            <Icon name="add" background={theme.colors.brandSecondary} />
          </Action>
        </SensorValuesHeader>
        <SensorValueList items={items} />
        <EditModal
          units={units}
          showEditModal={showEditModal}
          onModalConfirm={this.handleModalConfirm}
          onModalCancel={this.handleModalCancel}
        />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    sensorValues: getAllSensorValues(state) || {},
    currentSensorId: getCurrentSensorId(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetchSensorValues: (sensorId) => dispatch(sensorValues.list({ sensorId })),
  fetchSensorValue: (id) => dispatch(sensorValues.read(id)),
  fetchSensor: (id) => dispatch(sensors.read(id)),
  createSensorValue: (sensorId, attributes) =>
    dispatch(sensorValues.create({ sensorId }, attributes)),
  deleteSensorValue: (sensorValueId) =>
    dispatch(sensorValues.destroy(sensorValueId)),
  setCurrentSensorValue: (sensorValueId) =>
    dispatch(setCurrentSensorValue(sensorValueId)),
  resetCurrentSensorValue: () => dispatch(setCurrentSensorValue(null)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withTranslation()(SensorValues)))
