import { SET_CURRENT_SENSOR } from '../actions/currentSensor'

export default (state = null, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_CURRENT_SENSOR:
      return payload
    default:
      return state
  }
}
