import React, { Component } from 'react'
import camelize from 'camelize'
import styled from 'styled-components'
import { PropertyTable, PropertyTableRow } from './components'

const Button = styled.input`
  background-color: #5e6469;
  color: #fff;
  border: none;
  border-radius: 3px;
  display: inline-block;
  transition: 'border 0.2s linear, color 0.2s linear, width 0.2s linear, background-color 0.2s linear';
  cursor: pointer;
  padding: 5px 8px;
  height: 100%;
`

const ButtonWrapper = styled.div`
  width: 10em;
  height: 24px;
`

const WarningSign = () => (
  <p style={{ fontSize: '42px', color: '#cc3300', fontWeight: 'bold' }}>⚠</p>
)

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export default class ToggleMaintenanceMode extends Component {
  constructor(props) {
    super(props)

    this.state = {
      maintenanceModeActivated: camelize(props.centralModule).config
        .maintenanceMode,
    }
  }

  handleToggleClick = (e) => {
    e.preventDefault()

    const { toggleMaintenanceModeUrl, token } = this.props

    if (this.state.selectedPackage === '') {
      window.alert('Please select the target version first.')
      return
    }

    this.setState({ error: false, pending: true })

    window
      .fetch(toggleMaintenanceModeUrl, {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'same-origin',
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ error: false, pending: true })
          return response.json()
        } else {
          console.log('Error', response)
          this.setState({ error: true, pending: false })
        }
      })
      .then((data) => {
        if (data) {
          this.setState({
            maintenanceModeActivated: data.maintenance_mode,
            pending: false,
          })
        }
      })
      .catch((error) => console.error('Error:', error))
  }

  render() {
    const { maintenanceModeActivated, pending, error } = this.state

    const buttonText = maintenanceModeActivated ? 'deaktivieren' : 'aktivieren'

    const toggleMaintenanceModeButton = () => (
      <ButtonWrapper>
        <Button
          onClick={this.handleToggleClick}
          name="install"
          value={buttonText}
          type="submit"
          disabled={pending}
        />
      </ButtonWrapper>
    )

    return (
      <div>
        {error && (
          <ErrorMessage>
            <WarningSign /> Fehler beim aktivieren/deaktivieren des
            Wartungsmoduses
          </ErrorMessage>
        )}
        <PropertyTable
          rows={[
            {
              label: 'Wartungsmodus',
              value: maintenanceModeActivated ? 'aktiviert' : 'deaktiviert',
            },
          ]}
        >
          <PropertyTableRow
            label="Wartungsmodus"
            value={maintenanceModeActivated ? 'aktiviert' : 'deaktiviert'}
            actions={toggleMaintenanceModeButton}
          />
        </PropertyTable>
      </div>
    )
  }
}
