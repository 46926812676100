import { combineReducers } from 'redux'

import resources, * as fromResources from './resource'
import currentSensorId from './currentSensor'
import currentSensorValueId from './currentSensorValue'
import flashMessage, * as fromFlashMessage from '../../../../../redux/reducers/flashMessage'

export default () =>
  combineReducers({
    resources,
    currentSensorId,
    currentSensorValueId,
    flashMessage,
  })

// SensorValues
export const getAllSensorValues = (state) =>
  fromResources.sensorValues.getAll(state.resources)
export const getSensorValueById = (state, id) =>
  fromResources.sensorValues.getById(state.resources, id)
export const getCurrentSensorValueId = (state) => state.currentSensorValueId

// Sensors
export const getCurrentSensorId = (state) => state.currentSensorId
export const getSensorById = (state, id) =>
  fromResources.sensors.getById(state.resources, id)

// FlasshMessages
export const getAllMessages = (state) =>
  fromFlashMessage.getAll(state.flashMessage)
