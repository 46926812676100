import { SET_CURRENT_SENSOR_VALUE } from '../actions/currentSensorValue'

export default (state = null, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_CURRENT_SENSOR_VALUE:
      return payload
    default:
      return state
  }
}
